@use '@angular/material' as mat;
@use '../utils/colors';
@use '../themes/light-theme';
@use '../themes/dark-theme';

@mixin light-usermenu-theme($theme) {
  @include default-usermenu-theme($theme);

  .dropdown {
    background: colors.color-get(dark-theme.$dark-theme-primary, 900, 1) !important;
  }
}

@mixin dark-usermenu-theme($theme) {
  @include default-usermenu-theme($theme);

  .dropdown {
    color: white;
    background: rgba(35, 31, 32, 1);
  }
}

@mixin default-usermenu-theme($theme) {
  :host {
    height: 100%;
  }

  .toolbar-user-container {
    height: 100%;
    position: relative;

    .toolbar-user-btn {
      display: flex;
      justify-content: center;
      height: 100%;
      min-width: 220px;
      align-content: center;

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .name,
      .group {
        margin: 0 8px 0 10px;
        height: 50px;
      }

      .name {
        margin: 2px 15px 0;
        line-height: 1.4rem;
      }

      .active-role {
        font-size: 9pt;
      }

      .icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        transform: rotate(0);
        transition: transform 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      &.open {
        background: rgba(0, 0, 0, 0.05);

        .icon {
          transform: rotate(-180deg);
        }
      }
    }

    .dropdown {
      z-index: 2;
      position: absolute;
      width: 100%;
      min-width: 220px;
      opacity: 0;
      visibility: hidden;
      transition:
        visibility,
        opacity 0.25s linear,
        max-height 0.25s linear,
        opacity 0.25s linear;
      //background: colors.color-get(dark-theme.$dark-theme-primary, 900, 1) !important;

      .mat-mdc-list-base .mat-mdc-list-item {
        font-size: 14px;
      }

      @media screen and (max-width: 599px) {
        min-width: 65px;
      }

      &.open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
