@use '@angular/material' as mat;

@use './components/cube-dialog';
@use './components/cube-drawer';
@use './components/cube-main';
@use './components/cube-sidemenu';
@use './components/cube-toolbar';
@use './components/homepage';
@use './components/table-component';
@use './components/timeline';
@use './components/user-menu';

@use './themes/light-theme';
@use './themes/dark-theme';

@use './utils/colors';
@use './utils/typography';

@include mat.core();

// default dark-theme
@include mat.all-component-themes(dark-theme.$dark-theme);

.light-theme {
  @include mat.all-component-colors(light-theme.$light-theme);
  @include cube-main.light-cube-main-theme(light-theme.$light-theme);
  @include cube-sidemenu.light-cube-sidemenu-theme(light-theme.$light-theme);
  @include homepage.light-homepage-theme(light-theme.$light-theme);
  @include user-menu.light-usermenu-theme(light-theme.$light-theme);
  @include cube-dialog.light-dialog-theme(light-theme.$light-theme);
  @include cube-toolbar.light-cube-toolbar-theme(light-theme.$light-theme);
  @include table-component.light-table-theme(light-theme.$light-theme);
  @include cube-dialog.light-dialog-theme(light-theme.$light-theme);
  @include timeline.light-timeline-theme(light-theme.$light-theme);
}

.dark-theme {
  @include cube-main.dark-cube-main-theme(dark-theme.$dark-theme);
  @include cube-sidemenu.dark-cube-sidemenu-theme(dark-theme.$dark-theme);
  @include homepage.dark-homepage-theme(dark-theme.$dark-theme);
  @include user-menu.dark-usermenu-theme(dark-theme.$dark-theme);
  @include cube-dialog.light-dialog-theme(dark-theme.$dark-theme);
  @include cube-toolbar.dark-cube-toolbar-theme(dark-theme.$dark-theme);
  @include table-component.dark-table-theme(dark-theme.$dark-theme);
  @include cube-dialog.dark-dialog-theme(dark-theme.$dark-theme);
  @include timeline.dark-timeline-theme(dark-theme.$dark-theme);
}
