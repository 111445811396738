@use '@angular/material' as mat;
@use '../utils/colors';
@use '../themes/dark-theme';

@mixin light-cube-toolbar-theme($theme) {
  @include default-cube-toolbar-theme($theme);

  .page-toolbar {
    height: 48px;
    color: white;
    background: mat.m2-get-color-from-palette(colors.$rina-lightblue, 500);

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;

      &.active {
        color: mat.m2-get-color-from-palette(colors.$rina-darkblue, 500);
      }
    }
  }
}

@mixin dark-cube-toolbar-theme($theme) {
  @include default-cube-toolbar-theme($theme);

  .page-toolbar {
    background-color: rgba(35, 31, 32, 1);
    color: white;
    font-weight: 400;
    height: 48px;
    //position: sticky;
    //top: 0;
    //z-index: 3;

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;

      &.active {
        background-color: rgba(35, 31, 32, 1);
      }
    }
  }
}

@mixin default-cube-toolbar-theme($theme) {
  :host {
    z-index: 4;
  }

  .main-toolbar {
    height: 64px;
    padding-left: 16px;
  }

  .more-btn {
    height: 100%;
    min-width: 70px;
  }

  .mat-mdc-icon-button {
    margin-right: 10px;
  }
}
