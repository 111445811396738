@use '@angular/material' as mat;
@use '../utils/colors';

@mixin light-cube-main-theme($theme) {
  @include default-cube-main-theme($theme);
}

@mixin dark-cube-main-theme($theme) {
  @include default-cube-main-theme($theme);
}

@mixin default-cube-main-theme($theme) {
  .main-container {
    .sidenav,
    .drawer {
      background: mat.m2-get-color-from-palette(map-get($theme, primary), 400);
      background-image: linear-gradient(
        mat.m2-get-color-from-palette(map-get($theme, accent), 500, 1),
        mat.m2-get-color-from-palette(colors.$rina-foreground),
        500,
        1
      );
    }

    .sidenav {
      width: 250px;
    }

    .drawer {
      width: 80px;
    }
  }

  mat-toolbar.bg-logo,
  mat-toolbar.bg-logo.bg0f273c {
    position: relative;
    background: #0f273c !important;

    h1.logo {
      font-size: 20px;
      color: #ffffff;
      margin-left: 15px;
    }

    a.home {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .cube-toolbar {
    width: 100%;
    background: mat.m2-get-color-from-palette(colors.$rina-grayweb, 500);
    color: mat.m2-get-color-from-palette(colors.$rina-darkblue, 500);
  }

  .sidebar {
    width: 300px;
  }

  .content {
    margin-top: 2px;
  }

  .example-margin {
    margin: 0 10px;
  }

  .today {
    width: 100%;
    height: 22%;
    position: absolute;
    background: '#17161642';
    z-index: 1;
  }

  .today-time {
    margin: 0;
    color: white;
    z-index: 2;
  }

  .today-date {
    margin: 0;
    color: white;
    z-index: 2;
  }
}
