/* You can add global styles to this file, and also import other style files */
@use './assets/scss/utils/flexbox';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.danger-row {
  background-color: rgba(255, 76, 38, 0.3) !important;
}
